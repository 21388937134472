<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Previous Due Report
                            <v-spacer></v-spacer>
                            <print-buttons :passAuthToReport="true" v-if="downloadUrl && form.items.data.length"
                                           :download-url="downloadUrl"
                                           :pdf="false" label="Download Due Report"></print-buttons>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs4 sm4>
                                <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                                          label="Grade" v-model="grade" outlined dense/>
                            </v-flex>
                            <v-flex xs4 sm4>
                                <search-button style="" :disabled="!grade" permission="receipt-read"
                                               @action="get()">
                                    Search
                                </search-button>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :options.sync="pagination"
                                  :server-items-length="form.items.meta.total"
                                  :footer-props="footerProps"
                                  :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-left">{{item.roll}}</td>
                                <td class="text-xs-left">{{ item.enroll_code }}</td>
                                <td class="text-xs-left"> {{item.name}}</td>
                                <td class="text-xs-left">{{ item.grade}}<sup>"{{item.section}}"</sup></td>
                                <td class="text-xs-center">{{item.amount ? item.amount.numberFormat() : ''}}
                                </td>
                                <td class="text-xs-center">{{item.invoice_previous_due ?
                                    item.invoice_previous_due.paid_amount.numberFormat() : 0.00}}
                                </td>
                                <td class="text-xs-center">{{item.invoice_previous_due ?
                                    item.invoice_previous_due.due_amount.numberFormat() : 0.00}}
                                </td>
                            </tr>
                        </template>
                        <template v-slot:body.append v-if="totalSum > 0">
                            <tr>
                                <th colspan="4" class="text-xs-center">Total Sum:</th>
                                <th class="text-xs-center">{{totalSum.numberFormat()}}</th>
                                <th>{{totalPaidAmount.numberFormat()}}</th>
                                <th>{{totalDueAmount.numberFormat()}}</th>
                                <th></th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            gradeLoading: false,
            form: new Form({
                amount: '',
                enroll_code: '',
                stat: 0,
            }, '/api/report/previous-due-remaining'),
            pagination: {
                rowsPerPage: 50,
            },
            rowsPerPageItems: [50, 75, 100, 200, 500, 1000],
            headers: [
                {text: 'Roll', align: 'left', value: 'id', sortable: false,width:'3%'},
                {text: 'Enroll Code', align: 'left', value: 'enroll_code', sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false},
                {text: 'Uplaoded Due', align: 'left', value: 'total_amount', sortable: false},
                {text: 'Collected Due', align: 'left', value: 'paid_amount', sortable: false},
                {text: 'Remaining Due Amount', align: 'left', value: 'due_amount', sortable: false},
            ],
            grades: [],
            grade: '',
            downloadUrl: '',
            totalSum: 0,
            totalPaidAmount: 0,
            totalDueAmount: 0,
        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade + '&flag=true';
            },

            get(params) {
                if (this.grade) {
                    let extraParams = '&gradeId=' + this.grade + '&flag=true';
                    // let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                        this.totalSum = 0;
                        this.totalPaidAmount = 0;
                        this.totalDueAmount = 0;
                        data.data.map(res => {
                            if (!['', null, undefined].includes(res.amount)) {
                                if((res.invoice_previous_due && res.invoice_previous_due.paid_amount !== res.amount) || !res.invoice_previous_due){
                                    this.totalSum += parseFloat(res.amount);
                                    if (!['', null, undefined].includes(res.invoice_previous_due)) {
                                        this.totalPaidAmount += parseFloat(res.invoice_previous_due.paid_amount);
                                        this.totalDueAmount += parseFloat(res.invoice_previous_due.due_amount);
                                    }
                                }

                            }
                        });
                        this.downloadUrl = data.download_url;
                    })
                }
            },
            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = [{value: 'all', text: 'ALL'}];
                    data.data.map(item => {
                        this.grades.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
        }
    }
</script>
<style lang="scss">
    .verified {
        background: #4caf50 !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .total {
        background: red !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
    }

    .unverified {
        background: #fb8c00 !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
    }

    .inputType {
        border: 1px solid #bbb;
        text-align: center;
        height: 34px;
        width: 86px;

        &:focus {
            outline: none;
        }

        &[disabled] {
            color: #999;
        }
    }

    .inputType:disabled {
        cursor: not-allowed;
        background: #eee;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }
</style>